var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-5 mb-5" },
      _vm._l(_vm.teams, function(team, team_id) {
        return _c(
          "div",
          { key: team_id },
          [
            _c("div", { staticClass: "d-flex align-items-center ml-2 mb-2" }, [
              _c("div", { staticClass: "h2 mt-3 ml-2" }, [
                _vm._v(" " + _vm._s(team.name) + " ")
              ])
            ]),
            _c("AppTable", {
              attrs: {
                fields: _vm.fields,
                items: team.players,
                "sort-by": _vm.sortBy[team_id],
                "sort-desc": _vm.sortDesc[team_id]
              },
              on: {
                "update:sortBy": function($event) {
                  return _vm.$set(_vm.sortBy, team_id, $event)
                },
                "update:sort-by": function($event) {
                  return _vm.$set(_vm.sortBy, team_id, $event)
                },
                "update:sortDesc": function($event) {
                  return _vm.$set(_vm.sortDesc, team_id, $event)
                },
                "update:sort-desc": function($event) {
                  return _vm.$set(_vm.sortDesc, team_id, $event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(player)",
                    fn: function(data) {
                      return [
                        _c("img", {
                          staticClass: "agent-icon",
                          attrs: {
                            src: _vm.getAgentThumbnail(data.item.agent),
                            alt: data.item.agent_name
                          }
                        }),
                        _c("span", [_vm._v(" " + _vm._s(data.item.name) + " ")])
                      ]
                    }
                  },
                  {
                    key: "cell(k_d)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          {
                            class: {
                              "text-success": data.value > 0,
                              "text-danger": data.value < 0
                            }
                          },
                          [_vm._v(" " + _vm._s(data.value) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(fk_fd)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          {
                            class: {
                              "text-success": data.value > 0,
                              "text-danger": data.value < 0
                            }
                          },
                          [_vm._v(" " + _vm._s(data.value) + " ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }