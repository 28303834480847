<template>
  <div>
    <div class="mt-5 mb-5">
      <div v-for="(team, team_id) in teams" :key="team_id">
        <div class="d-flex align-items-center ml-2 mb-2">
          <div class="h2 mt-3 ml-2">
            {{ team.name }}
          </div>
        </div>

        <AppTable
          :fields="fields"
          :items="team.players"
          :sort-by.sync="sortBy[team_id]"
          :sort-desc.sync="sortDesc[team_id]"
        >
          <template #cell(player)="data">
            <img class="agent-icon" :src="getAgentThumbnail(data.item.agent)" :alt="data.item.agent_name" />
            <span>
              {{ data.item.name }}
            </span>
          </template>

          <template #cell(k_d)="data">
            <span
              :class="{
                'text-success': data.value > 0,
                'text-danger': data.value < 0,
              }"
            >
              {{ data.value }}
            </span>
          </template>

          <template #cell(fk_fd)="data">
            <span
              :class="{
                'text-success': data.value > 0,
                'text-danger': data.value < 0,
              }"
            >
              {{ data.value }}
            </span>
          </template>
        </AppTable>
      </div>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import AppTable from '@/components/generic/Table.vue'
import { pxNullable } from '@/components/Map/types'

export default {
  name: 'ScrimOverview',
  components: {
    AppTable,
  },
  props: {
    teams: pxNullable(px.arrayOf(px.object)),
  },
  data() {
    return {
      fields: [
        {
          key: 'player',
          label: 'Team Player',
          formatter: (value, key, item) => item.name,
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'kills',
          label: 'Kills',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'deaths',
          label: 'Deaths',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'assists',
          label: 'Assists',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'k_d',
          label: '+/- (K-D)',
          class: 'text-right',
          formatter: (value, key, item) => (item.kills !== undefined ? item.kills - item.deaths : 'N/A'),
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'head_shot_perc',
          label: 'HS%',
          class: 'text-right',
          formatter: value => `${Math.round((value + Number.EPSILON) * 100)}%`,
          sortable: true,
        },
        {
          key: 'first_kills',
          label: 'FK',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'first_deaths',
          label: 'FD',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'fk_fd',
          label: '+/- (FK-FD)',
          class: 'text-right',
          formatter: (value, key, item) =>
            item.first_kills !== undefined ? item.first_kills - item.first_deaths : 'N/A',
          sortable: true,
          sortByFormatted: true,
        },
      ],
      sortBy: {},
      sortDesc: {},
    }
  },

  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
    }),
  },

  watch: {
    teams: {
      immediate: true,
      deep: true,
      handler(teams) {
        if (teams) {
          for (const team_id in teams) {
            if (!(team_id in this.sortBy)) {
              this.$set(this.sortBy, team_id, 'player')
            }
            if (!(team_id in this.sortDesc)) {
              this.$set(this.sortDesc, team_id, false)
            }
          }
        }
      },
    },
  },
  methods: {
    getAgentThumbnail(agentId) {
      return this.agentsById[agentId]['display_icon_url']
    },
  },
}
</script>

<style lang="scss" scoped>
.agent-icon {
  height: 40px;
}

.team-logo {
  max-width: 50px;
  max-height: 60px;
}
</style>
